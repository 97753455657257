import { graphql } from 'gatsby';
import get from 'lodash/get';
import Img from 'gatsby-image';
import React from 'react';
import HeaderImage from 'components/HeaderImage';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';

const ContactPage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Policies" />
      <HeaderImage image={header}>
        <h1>Policies</h1>
      </HeaderImage>

      <div id="privacy">
        <h2>Privacy / Confidentiality</h2>
        <p>
          In order to help you with your software and career, we need to
          understand what’s going on. Sometimes, that’s going to involve the
          disclosure of sensitive or confidential information, whether it’s what
          you really think about that co-worker - or the code issue that’s
          keeping you awake at night.
        </p>
        <p>
          We will not share confidential information about you or your employer.
          We’re using what you tell us in order to help you, not for any other
          purpose. When you tell us something to help us do that, we also agree
          to become a party to any NDAs that permit disclosure under those
          terms.
        </p>
        <p>
          We’ll use our best judgment about what information is confidential,
          but if you think there’s something where it might be unclear, we ask
          that you clarify for us. For instance, the knowledge that your company
          is seeking to hire hardware engineers is not something we’d ordinarily
          expect to be confidential - but it might be, if that hardware is for a
          secret project.
        </p>
        <p>
          We may offer ways to help our clients network between each other - for
          instance, by passing business cards or resumes between interested
          parties, or posting them to a shared space. This is entirely optional,
          and we will not provide any information about you to another of our
          clients without your explicit permission.
        </p>
      </div>

      <div id="antidiscrimination">
        <h2>Antidiscrimination / LGBTQ</h2>
        <p>
          We believe that all people should be given the same rights and
          opportunities to succeed.
        </p>
        <p>
          We are aware that the software industry has a great deal of
          discrimination and implicit bias. We want to change the system, and we
          also want to help and support those people currently impacted by this
          broken system.
        </p>
        <p>
          We are particularly familiar with how this discrimination tends to
          impact women and LGBTQ+ people.
        </p>
      </div>

      <div id="sliding_scale">
        <h2>Sliding Scale</h2>
        <p>
          Not everyone has the same access to financial resources. If you’d like
          to work with us, but are financially limited, let us know. We may be
          able to work something out.
        </p>
        <p>
          On the other side of it, if you’re financially fortunate and would
          like to support others by making our services accessible to them, let
          us know; we’d be glad to talk about how you can help underwrite our
          sliding scale and defray those costs for those in need.
        </p>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query PoliciesPageQuery {
    header: file(name: { eq: "rawpixel-769368-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 800, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
